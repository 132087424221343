import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { getProtocols, type ProtocolsResponse } from '../utils/apiHelpers'

export function useProtocols() {
  const token = useSelector<AppState, string>((state) => state.user.token)

  return useQuery({
    enabled: !!token,
    queryKey: ['protocols'],
    queryFn: async () => {
      const response = await getProtocols(token)

      return response.data.data.protocols.reduce((acc, protocol) => {
        acc[protocol.protocol_id.toLowerCase()] = protocol

        return acc
      }, {}) as Record<string, ProtocolsResponse['data']['protocols'][0]>
    },
  })
}
