import { createSlice } from '@reduxjs/toolkit'

export interface AuthState {
  userName: string
  userId: string
  token: string
  error?: boolean
}

const initialState: AuthState = {
  userId: '',
  userName: '',
  token: '',
  error: false,
}

export const AuthSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    userLoginStatus: (state, action) => {
      state = { ...state, ...action.payload }
      return state
    },
    clearLoginStatus: (state) => {
      state.userId = ''
      state.userName = ''
      state.token = ''
      state.error = false
      return state
    },
  },
})

export const { userLoginStatus, clearLoginStatus } = AuthSlice.actions

export default AuthSlice.reducer
