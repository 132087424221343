import { useState, useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { fetchPremiumInfo, fetchUsdcAmtForOneDollar } from 'utils/apiHelpers'
import { AppState, useAppDispatch } from 'state'
import { useSelector } from 'react-redux'

const useGetPremiumPrices = (data, amount, duration, discount) => {
  const [premiums, setPremiums] = useState([])
  const [premiumTotal, setPremiumTotal] = useState(0)
  const [isSent, setIsSent] = useState(false)
  const token = useSelector<AppState>((state) => state.user.token)

  useEffect(() => {
    const fetch = async () => {
      setIsSent(true)
      const res: any = await fetchPremiumInfo(data, token)
      if (res?.success) {
        setPremiums(res.data.premiums)
        setPremiumTotal(res.data.premium)

        setTimeout(() => {
          setIsSent(false)
        }, 1000)
      }
    }
    fetch()
  }, [amount, duration, discount])

  return { premiums, premiumTotal, isSent }
}

export const useGetCoverTotalInUsd = (data, coverAmount) => {
  const [coverTotalInUsd, setCoverTotalInUsd] = useState(0)

  useEffect(() => {
    const sum = async () => {
      let sumValue = BIG_ZERO
      data.forEach((_, index) => {
        sumValue = sumValue.plus(new BigNumber(_.coverAmount))
        if (index === data.length - 1) {
          setCoverTotalInUsd(sumValue.toNumber())
        }
      })
    }
    if (data) {
      sum()
    }
  }, [data, coverAmount])

  return { coverTotalInUsd }
}

export const useGetUSDCAmtForOneDollar = () => {
  const [usdcAmtForOneDollar, setUsdcAmtForOneDollar] = useState(1)

  useEffect(() => {
    const get = async () => {
      const res: any = await fetchUsdcAmtForOneDollar()
      if (res?.success) {
        setUsdcAmtForOneDollar(res.data.usdcAmtForOneDollar)
      }
    }
    get()
  }, [])

  return { usdcAmtForOneDollar }
}

export default useGetPremiumPrices
