import axios from 'axios'
import { BASE_API_URL, COINGECKO_BASE_URL } from 'config'
import { usdPriceTokenList } from 'config/constants/tokens'

/**
 * This is the API helper file. All the API calls should be made from this file.
 */
const api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'https://staging-admin.unore.io/api' : 'https://admin.unore.io/api',
})

type UploadImage = {
  file: File
}

export async function uploadLogo({ file }: UploadImage) {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  const fileName = file.name.split('.')[0]

  return await api.postForm<{ url: string }>(
    `/upload-logo/${fileName}`,
    {
      logo: file,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

export async function checkExistingFile(filename: string) {
  const formattedName = filename.replace(/\s/g, '_')

  try {
    const response = await api.get(`/get-logo/${formattedName}`)

    return response.config.baseURL + response.config.url
  } catch (_) {
    return false
  }
}

type Protocol = {
  _id: string
  protocol_id: string
  product_name: string
  product_description: string
  product_logo: string
  product_link: string
  product_type: string
  address: string
  status: string
  premium_factor: number
  DAO_approval_status: string
  chains: string[]
  created_at: string
  updated_at: string
}

export type ProtocolsResponse = {
  success: boolean
  message: string
  data: {
    totalItems: number
    totalPages: number
    currentPage: number
    protocols: Protocol[]
  }
}

export async function getProtocols() {
  return api.get<ProtocolsResponse>('/v1/protocol')
}

export const axiosGetMain = (endpoint) => {
  const url = `${COINGECKO_BASE_URL}${endpoint}`
  const header = {}

  return axios
    .get(url, {
      headers: header,
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      return {
        status: 500,
        data: 'Failed!',
        error,
      }
    })
}

export const fetchUsdPrices = async () => {
  const returnRes = {}
  const _calls = usdPriceTokenList.map(async (token) => {
    returnRes[`${token.symbol}`] = 0
    return axiosGetMain(`simple/price?ids=${token.id}&vs_currencies=usd`)
  })

  const prices = await Promise.all(_calls).then((res) => {
    return res
  })
  for (let i = 0; i < usdPriceTokenList.length; i++) {
    returnRes[`${usdPriceTokenList[i].symbol}`] =
      prices[i].status === 200 ? prices[i].data[`${usdPriceTokenList[i].id}`].usd : 0
  }
  return returnRes
}

export const signup = (body: any) => {
  return new Promise((resolve, reject) => {
    try {
      axios.post(`${BASE_API_URL}signup`, body).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const signin = (body: any) => {
  return new Promise((resolve, reject) => {
    try {
      axios.post(`${BASE_API_URL}signin`, body).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const getBlacklistAll = (page, limit) => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(`${BASE_API_URL}blacklist?page=${page + 1}&limit=${limit}`).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const addBlackList = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${BASE_API_URL}blacklist`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const updateBlacklist = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .patch(`${BASE_API_URL}blacklist`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const deleteBlacklist = (id: string, address) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(`${BASE_API_URL}blacklist`, {
          data: { chain_id: id, address },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const getProtocolListAll = () => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(`${BASE_API_URL}protocol`).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const getProtocolListsWithPagination = (page: number, limit: number, status: number[], search?: string) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${BASE_API_URL}protocol?limit=${limit}&page=${page + 1}&search=${search}&status=${status}`)
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const addProtocol = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${BASE_API_URL}protocol`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}
export const updateProtocol = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .patch(`${BASE_API_URL}protocol`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}
export const deleteProtocol = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(`${BASE_API_URL}protocol`, {
          data: body,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}
export const fetchUsdcAmtForOneDollar = async () => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(`https://cover.unore.io/api/usdc-price`).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}
export const fetchPremiumInfo = async (data, token) => {
  const body = {
    data,
  }
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`https://cover.unore.io/api/getcoverusdprice`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}
export const fetchFunctionSignature = async (body) => {
  return new Promise((resolve, reject) => {
    try {
      axios.post(`https://cover.unore.io/api/get-signature`, body).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}
export const fetchFunctionSignatureInRinkeby = async (body) => {
  return new Promise((resolve, reject) => {
    try {
      axios.post(`https://cover.unore.io/api/get-signature-rinkeby`, body).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const fetchChainListAll = () => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(`${BASE_API_URL}chain`).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const fetchChainList = (page: number, limit: number, search = '') => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(`${BASE_API_URL}chain?limit=${limit}&page=${page + 1}&search=${search}`).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const addChain = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${BASE_API_URL}chain`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}
export const updateChain = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .patch(`${BASE_API_URL}chain`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}
export const deleteChain = (id: string) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(`${BASE_API_URL}chain`, {
          data: { _id: id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}

// token management CRUD

export const fetchTokenList = (page: number, limit: number, search = '') => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(`${BASE_API_URL}token?limit=${limit}&page=${page + 1}&search=${search}`).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const fetchTokenListAll = () => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(`${BASE_API_URL}token`).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const addToken = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${BASE_API_URL}token`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}
export const updateToken = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .patch(`${BASE_API_URL}token`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}
export const deleteToken = (id: string) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(`${BASE_API_URL}token`, {
          data: { _id: id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}

// Product type management CRUD

export const fetchPTypeList = (page: number, limit: number, search = '') => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(`${BASE_API_URL}product_type?limit=${limit}&page=${page + 1}&search=${search}`).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const addPType = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${BASE_API_URL}product_type`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}
export const updatePType = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .patch(`${BASE_API_URL}product_type`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}
export const deletePType = (id: string) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(`${BASE_API_URL}product_type`, {
          data: { _id: id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const getWhitelistAll = (page, limit) => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(`${BASE_API_URL}whitelist?page=${page + 1}&limit=${limit}`).then((res) => {
        resolve(res.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const addWhitelist = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${BASE_API_URL}whitelist`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const updateWhitelist = (body: any) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .patch(`${BASE_API_URL}whitelist`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const deleteWhitelist = (chain_id: string, address: string, protocol_id: string) => {
  const storage = localStorage.getItem('redux_localstorage_simple')
  const token = JSON.parse(storage).user.token

  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(`${BASE_API_URL}whitelist`, {
          data: { chain_id, address, protocol_id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res.data)
        })
    } catch (err) {
      reject(err)
    }
  })
}
