import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import { load, save } from 'redux-localstorage-simple'
import { useDispatch } from 'react-redux'
import userReducer from './auth'
import poolsReducer from './pools'

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    user: userReducer,
    pools: poolsReducer,
  },
  middleware: [thunkMiddleware, save()],
  preloadedState: load(),
})

export type AppDispatch = typeof store.dispatch | any
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch()

export default store
